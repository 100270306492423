import React from 'react';

const SmallCard = ({ icon, title }) => {
    return (
        <div className="small-card">
            <img src={icon} alt="icon" className="icon" />
            <span className="title">{title}</span>
        </div>
    );
};

export default SmallCard;
