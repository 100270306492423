import React from 'react';

import chevronRight from '../../assets/chevron-right.svg';
import Button from './Button';

const InsuranceCard = ({
    title,
    count,
    summaryPrice,
    onClick,
    noChevron,
    disabled,
    buttonTitle,
    buttonClick,
    isBlue,
    isGreen,
    isButtonYellow,
    subtitle,
    rightIcon,
}) => {
    return (
        <div
            className={`insurance-card ${disabled ? 'disabled' : ''} ${isBlue ? 'blue' : ''} ${isGreen ? 'green' : ''}`}
            onClick={disabled ? undefined : onClick}
        >
            <div className="left-side">
                <span className="title">{title}</span>
                <div className="info-row">
                    {subtitle && <span className="description">{subtitle}</span>}
                    {count !== undefined && (
                        <div className="left">
                            <span className="title">{count}</span>
                        </div>
                    )}
                    {(summaryPrice !== undefined && !disabled) && (
                        <div className="right">
                            <span className="title">{summaryPrice} €</span>
                            <span className="subtitle">Ausgaben jährlich</span>
                        </div>
                    )}
                    {buttonTitle && (
                        <Button
                            title={buttonTitle}
                            className="button"
                            onClick={buttonClick}
                            isYellow={isButtonYellow}
                        />
                    )}
                </div>
            </div>
            {(!disabled || rightIcon) && !noChevron && <img src={rightIcon ?? chevronRight} alt="chevron-right" className="chevron" />}
        </div>
    );
};

export default InsuranceCard;
