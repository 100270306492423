/**
 * Created by Max Gornostayev on 06/4/22
 *
 * pageId: secureContractDetail
 * path: /contract
 * props:
 * @contractId - int - contract id
 *
 * this is a page that show one detail contract
 */

import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/buttons/Button';
import ButtonLink from '../../../components/buttons/ButtonLink';
import InputText from '../../../components/forms/InputText';
import Bottom from '../../../components/theme/Bottom';
import ProviderImage from '../../../components/elements/ProviderImage';
import Loading from '../../../components/elements/Loading';
import Alert from '../../../components/popups/Alert';
import pageUrl from '../../../const/pages';
import trans from '../../../trans';
import Utils from '../../../lib/Utils';
import DateUtil from '../../../lib/DateUtil';
import '../../../styles/secure.scss';
import Header from '../../../components/theme/HeaderV2';

const ContractDetailPage = observer(({ ContractsStore, UserStore }) => {
    //get state for getting props
    const { state } = useLocation();

    //get prop 'contractId' from another screen
    const contractId = state && state.hasOwnProperty('contractId') ? state.contractId : 0;

    //get contract
    const contract = ContractsStore.getDetailedContract(contractId);

    //state variables
    const [notes, setNotes] = useState(contract.notesValue);
    const [isLoading, setIsLoading] = useState(false);

    //ref variable for popup
    const refAlert = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //delete contract function
    const deleteContract = async () => {
        setIsLoading(true);
        const res = await ContractsStore.removeContract(contractId);
        setIsLoading(false);
        if (!res.status) {
            refAlert.current.error(res.msg);
            return false;
        }
        navigate(pageUrl.secureContractsList);
        return true;
    };

    //save contract
    const saveContract = async () => {
        setIsLoading(true);
        const res = await ContractsStore.updateContract(contractId, { notes });
        setIsLoading(false);
        if (!res.status) {
            refAlert.current.error(res.msg);
            return false;
        }
        refAlert.current.show(trans.t('secureContractDetailPage', 'contractUpdatedText'));
        return true;
    };

    //get current customer
    const { customer } = UserStore;

    //get props of contracts
    const {
        productTitle,
        providerTitle,
        brokerStatusValue,
        numberValue,
        yearlyCostValue,
        statusTitle,
        numberDocumentsValue,
        tariffsArr,
        paymentMethodTitle,
        priceValue,
        startDateValue,
        endDateValue,
    } = contract;

    //render function
    return (
        <div className="app secure-contract-detail-page">
            {/* <HeaderSecure isBack>

            </HeaderSecure> */}
            <Header backButton noMenu title="Details zum Vertrag" />
            <div className="info">
                <div className="inner">
                    <div>
                        <div className="title">{providerTitle}</div>
                        <h1 className="product-title">{productTitle}</h1>
                    </div>
                    <div className="detail">
                        <div className="detail-item">
                            <ProviderImage title={providerTitle} isHeaderStyle />
                        </div>
                        <div className="detail-item">
                            <div className="title">{trans.t('secureContractDetailPage', 'contractNumber')}.:</div>
                            <div>{numberValue || '-'}</div>
                        </div>
                        <div className="detail-item">
                            <div className="title">{trans.t('secureContractDetailPage', 'contractYearlyCost')}:</div>
                            <div>{Utils.formatPrice(yearlyCostValue)}</div>
                        </div>
                        <div className="detail-item">
                            <div className="title">{trans.t('secureContractDetailPage', 'contractStatus')}:</div>
                            <div>{statusTitle || '-'}</div>
                        </div>
                        <div className="detail-item">
                            <div className="title">{trans.t('secureContractDetailPage', 'contractCashback')}:</div>
                            <div className="cashback">{trans.t('secureContractDetailPage', 'brokerStatus_' + brokerStatusValue)}</div>
                        </div>
                    </div>
                </div>
                <div className="inner-buttons">
                    <Button
                        text={trans.tParams('secureContractDetailPage', 'btnDocuments', { numberDocuments: numberDocumentsValue })}
                        onClick={() => navigate(pageUrl.secureContractDocuments, { state: { contractId } })}
                        isTransparent
                        isRightIcon
                        isLeftAlign
                        role="btnDocuments"
                    />
                    <Button text={trans.t('secureContractDetailPage', 'btnSupport')} onClick={() => {}} isTransparent isRightIcon isLeftAlign role="btnSupport" />
                    <Button text={trans.t('secureContractDetailPage', 'btnCheckContract')} onClick={() => {}} isTransparent isRightIcon isLeftAlign role="btnContact" />
                </div>
            </div>
            <div className="content">
                <div className="wrapper-big">
                    <div className="contract">
                        <h4>{trans.t('secureContractDetailPage', 'titleDetail')}</h4>
                        <div className="item">
                            <div className="title">{trans.t('secureContractDetailPage', 'contractOwner')}:</div>
                            <div>{customer.fullName || '-'}</div>
                        </div>
                        <div className="item">
                            <div className="title">{trans.t('secureContractDetailPage', 'contractTariff')}:</div>
                            {tariffsArr.length ? tariffsArr.map((tariff, index) => <div key={index}>{tariff}</div>) : '-'}
                        </div>
                        <h4>{trans.t('secureContractDetailPage', 'titlePrice')}</h4>
                        <div className="item">
                            <div className="title">{trans.t('secureContractDetailPage', 'contractPaymentMethod')}:</div>
                            <div>{paymentMethodTitle || '-'}</div>
                        </div>
                        <div className="item">
                            <div className="title">{trans.t('secureContractDetailPage', 'contractPrice')}:</div>
                            <div>{Utils.formatPrice(priceValue)}</div>
                        </div>
                        <h4>{trans.t('secureContractDetailPage', 'titleDates')}</h4>
                        <div className="item">
                            <div className="title">{trans.t('secureContractDetailPage', 'contractStartDate')}:</div>
                            <div>{startDateValue ? DateUtil.getFormatDate(startDateValue) : '-'}</div>
                        </div>
                        <div className="item">
                            <div className="title">{trans.t('secureContractDetailPage', 'contractEndDate')}:</div>
                            <div>{endDateValue ? DateUtil.getFormatDate(endDateValue) : '-'}</div>
                        </div>
                        <InputText
                            title={trans.t('secureContractDetailPage', 'fieldNotes')}
                            defaultValue={contract.notesValue}
                            onChange={(val) => setNotes(val)}
                            className="margin-top-16"
                            role="contractNoteTestId"
                        />
                        <ButtonLink role="btnDelete" onClick={deleteContract} text={trans.t('secureContractDetailPage', 'btnDelete')} className="margin-top-32" />
                    </div>
                </div>
            </div>
            {!!notes && (
                <Bottom>
                    <Button role="btnSave" text={trans.t('secureContractDetailPage', 'btnSave')} onClick={saveContract} />
                </Bottom>
            )}
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default ContractDetailPage;
