/**
 * Created by Max Gornostayev on 05/25/22
 *
 * pageId: secureDashboard
 * path: /dashboard
 *
 * this is a main page that user see after success login
 */

import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
// import HeaderSecure from '../../components/theme/HeaderSecure';
// import ButtonDashboard from '../../components/buttons/ButtonDashboard';
// import Button from '../../components/buttons/Button';
// import config from '../../config';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/secure.scss';

import Header from '../../components/theme/HeaderV2';
import DashboardCard from '../../components/dashboard/DashboardCard';
import share from '../../assets/share.svg';
import SmallCard from '../../components/dashboard/SmallCard';
import customize from '../../assets/customize.svg';
import coins from '../../assets/coins.svg';
import { INSURANCE_CONTRACTS_CATEGORIES } from '../../const/contract';

const DashboardPage = observer(({ UserStore, ContractsStore, DataStore }) => {
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //get current customer
    const { customer } = UserStore;

    const insuranceData = useMemo(() => {
        const insuranceProductIds = DataStore.getProductsByCategories(INSURANCE_CONTRACTS_CATEGORIES);
        const insuranceContracts = Array.from(ContractsStore.contracts.values()).filter((contract) => insuranceProductIds.includes(contract.productType));

        const insuranceContractsSummary = insuranceContracts.reduce((acc, contract) => {
            acc += contract.yearlyCostValue;

            return acc;
        }, 0);

        return {
            count: insuranceContracts.length,
            totalPrice: insuranceContractsSummary,
        };
    }, [ContractsStore.contracts]);

    //main rendering
    return (
        <div className="app secure-dashboard-page">
            {/* <HeaderSecure selected={pageUrl.secureDashboard} isTopMenu isSlideMenu>
                <div className="wrapper-big">
                    <h3>{trans.tParams('secureDashboardPage', 'titleHeader', { name: customer.firstNameValue })}</h3>
                    <div className="row-spacebetween-center">
                        <div className="row-left-center">
                            <ButtonDashboard
                                icon={IconTodo}
                                titleNumber={ContractsStore.getNumberOfContracts + ' '} // number of all contracts
                                titleText={trans.t('secureDashboardPage', 'contracts')}
                                onClick={() => navigate(pageUrl.secureContractsList)}
                            />
                            <ButtonDashboard icon={IconCoins} titleNumber={ContractsStore.getPayments + ' EUR'} titleText={trans.t('secureDashboardPage', 'payments')} />
                        </div>
                        <ButtonDashboard icon={IconQR} titleText={trans.t('secureDashboardPage', 'qrText')} onClick={() => navigate(pageUrl.secureReferral)} />
                    </div>
                </div>
            </HeaderSecure> */}
            <Header />
            <div className="main-content dashboard">
                {/* <div className="wrapper-big">
                    <div className="row-spacebetween-center">
                        <h3>{trans.t('secureDashboardPage', 'titleTicket')}</h3>
                        <Button text={trans.t('secureDashboardPage', 'btnTitle')} onClick={() => window.open(config.urls.newTicket, '_blank').focus()} isRightIcon role="btnNext" />
                    </div>
                    <div className="text">{trans.t('secureDashboardPage', 'textTicket')}</div>
                </div> */}
                <div className="title">
                    {trans.tParams('secureDashboardPage', 'titleHeader', { name: customer.firstNameValue })}
                </div>
                <div className="cards-wrapper">
                    <DashboardCard
                        title={trans.t('dashboardPage', 'insurance')}
                        count={insuranceData.count}
                        summaryPrice={insuranceData.totalPrice}
                        chevron
                        disabled={insuranceData.count === 0}
                        onClick={() => navigate(pageUrl.secureContractsList)}
                    />
                    <DashboardCard
                        title={trans.t('dashboardPage', 'energyContract')}
                        count={insuranceData.count}
                        summaryPrice={insuranceData.totalPrice}
                        disabled={insuranceData.count === 0}
                    />
                    <DashboardCard
                        title={trans.t('dashboardPage', 'сashbackAccount')}
                        count={8}
                        buttonTitle={trans.t('dashboardPage', 'showAccount')}
                        isButtonYellow
                        isBlue
                        noChevron
                    />
                    <DashboardCard
                        title={trans.t('dashboardPage', 'shareCommunity')}
                        subtitle={trans.t('dashboardPage', 'shareCommunityText')}
                        rightIcon={share}
                        onClick={() => navigate(pageUrl.sharing)}
                        isGreen
                    />
                </div>
                <div className="buttons-row">
                    <SmallCard title={trans.t('dashboardPage', 'businessPartnerDashboard')} icon={customize} />
                    <SmallCard title={trans.t('dashboardPage', 'buyCompanyTokens')} icon={coins} />
                </div>
            </div>
        </div>
    );
});

export default DashboardPage;
