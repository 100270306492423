/**
 * Created by Max Gornostayev on 05/28/22
 *
 * this is slide menu
 */

import { useNavigate } from 'react-router-dom';
import pageURLs from '../../const/pages';
import config from '../../config';
import trans from '../../trans';
import IconSlideMenu from '../../assets/icon-slide-menu-white.png';
import { observer, MobXProviderContext } from 'mobx-react';
import { useContext } from 'react';

const SlideMenu = observer(({ isOpened, closeFunc }) => {
    const { UserStore, ContractsStore } = useContext(MobXProviderContext);
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //determine the classname
    let className = 'menu-slide';
    if (isOpened) {
        className += ' opened';
    }

    //handler for logout
    const logout = () => {
        ContractsStore.clearStore();
        UserStore.clearStore();
        navigate(pageURLs.authStartLogin);
    };

    return (
        <div className={className}>
            <div className="menu-slide-inner">
                <div className="icon-menu" onClick={closeFunc}>
                    <img src={IconSlideMenu} alt="slide" />
                </div>
                <div className="item" onClick={() => navigate(pageURLs.secureProfile)}>
                    {trans.t('slideMenu', 'profile')}
                </div>
                <div className="item" onClick={() => window.open(config.urls.impressumLink, '_blank').focus()}>
                    {trans.t('slideMenu', 'impressum')}
                </div>
                <div className="item" onClick={logout}>
                    {trans.t('slideMenu', 'logout')}
                </div>
            </div>
        </div>
    );
});

export default SlideMenu;
