import React from 'react';

const Button = ({ className = '', title, onClick, yellow, big }) => {
    return (
        <button className={`dashboard-button ${className} ${yellow ? 'yellow' : ''} ${big ? 'big' : ''}`} onClick={onClick}>
            {title}
        </button>
    );
};

export default Button;
